import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

class HomeDrop extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    const {
      dropData, assetData, layoutStyle,
    } = this.props;
    return (
      <div id={layoutStyle === 'default' ? 'moreInfoElement' : ''} className={layoutStyle === 'offset' ? 'featured-drop__outer -offset' : 'featured-drop__outer -inverse'}>
        <div className="landing-featured-drop">
          <div className="featured-drop__row">
            <div className={layoutStyle === 'offset' ? 'featured-drop__col-image -offset' : 'feature-drop-image__home featured-drop__col-image'}>
              {assetData.mediaMP4
                ? (
                  <video className="feature-drop__home" autoPlay="autoplay" loop muted>
                    <source src={assetData.mediaMP4} type="video/mp4" />
                    <track
                      default
                      kind="captions"
                      srcLang="en"
                      src="" />
                    Your browser does not support the video tag.
                  </video>
                )
                : <Link to={'/drop/' + dropData.dropId}><img src={assetData.mediaThumbnailUrl || assetData.mediaUrl} alt="ethos" /></Link>}
            </div>
            <div className={layoutStyle === 'offset' ? 'featured-drop__col-text -offset' : 'featured-drop__col-text__home featured-drop__col-text'}>
              <div className="featured-drop__vertical-center">
                <div
                  className="featured-drop__title"
                  dangerouslySetInnerHTML={{ __html: assetData.name }}>
                </div>
                <div
                  className="featured-drop__description">
                  <p dangerouslySetInnerHTML={{ __html: assetData.assetDescription }}></p>
                </div>
                <div className="featured-drop__description -padding-bottom">{process.env.PURCHASE_INSTRUCTIONS}</div>

                <div className="featured-drop__cta">
                  <Link className="button" to={'/drop/' + dropData.dropId}>View Details</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

HomeDrop.propTypes = {
  dropData: PropTypes.objectOf(PropTypes.object),
  assetData: PropTypes.objectOf(PropTypes.object),
  layoutStyle: PropTypes.string,
};

export default HomeDrop;
