import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import AccountManager from '../../managers/Account';
import Throbber from '../throbber';

export default function CollectionDetails(props) {
  const [isLoading, setIsLoading] = useState(true);

  const { nftId } = useParams();

  const getDetails = async (nftId) => {
    // get the collection from the server
    setIsLoading(true);
    AccountManager.getNftDetails(AccountManager.getToken(), nftId).then((res) => {
      setIsLoading(false);
      console.log('=== YOUR NFT ===');
      console.log(res);
    }).catch((error) => {
      console.log(error);
      setIsLoading(false);
      toast.error('Oops. There was a problem getting your collection data. Please try again later.');
    });
  };

  useEffect(() => {
    getDetails(nftId);
  }, []);

  return (
    <div>
      <div className="account-box -account-section -no-right-padding">
        {isLoading
            && <Throbber throbberText="Loading NFT details! Please stand by..." />}
        <h2 className="account-heading__h2">
          Account Collection
        </h2>
        <div className="account-subheading">
          View your digital assets here
        </div>
      </div>
    </div>
  );
}
