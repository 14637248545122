import React, { Component } from 'react';

class FullPageLoader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="full-site-loader">
        <div className="full-site-loader__content">
          <img src={process.env.LARGE_LOGO_INVERSE || process.env.LARGE_LOGO} alt="ethos" width={process.env.LARGE_LOGO_WIDTH} />
          {/* <div className="full-site-loader__text">Loading...</div> */}
        </div>
      </div>
    );
  }
}

export default FullPageLoader;
