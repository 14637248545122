const isIOS = function () {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod',
  ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
};

const getParameterByName = function (name, url) {
  let newName = name;
  let newUrl = url;
  if (!url) newUrl = window.location.href;
  newName = name.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + newName + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(newUrl);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

const getBlockchainType = function (chainId) {
  let chainname = 'Flow';
  if (!chainId) {
    chainname = 'Flow';
  } else if (chainId === 0) {
    chainname = 'Flow';
  } else if (chainId === 1) {
    chainname = 'Ethereum';
  } else if (chainId === 2) {
    chainname = 'Polygon';
  }
  return chainname;
};

export { isIOS };
export { getParameterByName };
export { getBlockchainType };
