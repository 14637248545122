import React, { Component } from 'react';
import { toast } from 'react-toastify';
import PubSub from 'pubsub-js';
import AccountManager from '../../managers/Account';
import Throbber from '../throbber';

const emailPattern = /^[-a-z0-9~!$%^&*_=+}{'?]+(\.[-a-z0-9~!$%^&*_=+}{'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;

class AccountDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountDetails: null,
      isLoading: false,
      updateFirstname: '',
      updateLastname: '',
      updateEmail: '',
      updateUsername: '',
      updateTelephone: '',
    };
  }

  async componentDidMount() {
    // get the account details from the AccountManager
    // we can assume that the user is already logged in here
    const accountData = await AccountManager.get(AccountManager.getToken());

    this.setState({
      accountDetails: accountData,
      updateFirstname: accountData.firstName,
      updateLastname: accountData.lastName,
      updateEmail: accountData.email,
      updateUsername: accountData.username,
      updateTelephone: accountData.telephone,
    });
  }

  onEnterKey = (e) => {
    if (e.keyCode === 13) {
      this.validateForm();
    }
  }

  handleInputChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  }

  validateForm = () => {
    const { updateFirstname } = this.state;
    const { updateLastname } = this.state;
    const { updateEmail } = this.state;
    const { updateUsername } = this.state;
    const { updateTelephone } = this.state;
    if (updateEmail === ''
      || updateFirstname === ''
      || updateLastname === ''
      || updateUsername === ''
      || updateTelephone === '') {
      toast.error('Please fill out all fields.');
      return false;
    }
    if (!emailPattern.test(updateEmail)) {
      toast.error('Please enter a valid email address.');
      return false;
    }
    return true;
  }

  updateDetails = async (e) => {
    const { updateFirstname } = this.state;
    const { updateLastname } = this.state;
    const { updateEmail } = this.state;
    const { updateUsername } = this.state;
    const { updateTelephone } = this.state;
    e.preventDefault();
    let payload = {};
    payload = {
      email: updateEmail,
      firstname: updateFirstname,
      lastname: updateLastname,
      username: updateUsername,
      telephone: updateTelephone,
    };
    if (this.validateForm()) {
      this.setState({
        isLoading: true,
      });
      const accountChanges = await AccountManager.update(payload, AccountManager.getToken());
      if (accountChanges) {
        this.setState({
          accountDetails: payload,
          isLoading: false,
        });
        PubSub.publish('account_updated');
        toast.success('Account saved!');
      }
    }
  }

  render() {
    const { accountDetails } = this.state;
    const { updateFirstname } = this.state;
    const { updateLastname } = this.state;
    const { updateEmail } = this.state;
    const { updateTelephone } = this.state;
    const { updateUsername } = this.state;
    const { isLoading } = this.state;
    return (
      <div>
        {accountDetails
          && (
            <div>
              <div className="account-box -account-section">
                {isLoading
                && <Throbber throbberText="Updating your details! Please stand by..." />}
                <h2 className="account-heading__h2">
                  Contact Details
                </h2>
                <div className="account-subheading">
                  View and update your contact details here
                </div>
                <div className="form-container">
                  <div className="form-section">
                    <label className="label-block" htmlFor="updateFirstname">First name</label>
                    <input
                      className="input-block"
                      id="updateFirstname"
                      type="text"
                      value={updateFirstname}
                      onChange={(e) => this.handleInputChange(e)}
                      onKeyUp={(e) => this.onEnterKey(e)} />
                  </div>
                  <div className="form-section">
                    <label className="label-block" htmlFor="updateLastname">Last name</label>
                    <input
                      className="input-block"
                      id="updateLastname"
                      type="text"
                      value={updateLastname}
                      onChange={(e) => this.handleInputChange(e)}
                      onKeyUp={(e) => this.onEnterKey(e)} />
                  </div>
                  <div className="form-section">
                    <label className="label-block" htmlFor="updateEmail">Email</label>
                    <input
                      className="input-block"
                      id="updateEmail"
                      type="email"
                      value={updateEmail}
                      onChange={(e) => this.handleInputChange(e)}
                      onKeyUp={(e) => this.onEnterKey(e)} />
                  </div>
                  <div className="form-section">
                    <label className="label-block" htmlFor="updateUsername">Username</label>
                    <input
                      className="input-block"
                      id="updateUsername"
                      type="text"
                      value={updateUsername}
                      onChange={(e) => this.handleInputChange(e)}
                      onKeyUp={(e) => this.onEnterKey(e)} />
                  </div>
                  <div className="form-section">
                    <label className="label-block" htmlFor="updateTelephone">Phone number</label>
                    <input
                      className="input-block"
                      id="updateTelephone"
                      type="text"
                      value={updateTelephone}
                      onChange={(e) => this.handleInputChange(e)}
                      onKeyUp={(e) => this.onEnterKey(e)} />
                  </div>
                  <div className="form-cta">
                    <button
                      className="button"
                      type="submit"
                      onClick={(e) => this.updateDetails(e)}>
                      Update Details
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
      </div>
    );
  }
}

export default AccountDetails;
