import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Throbber from '../throbber';
import AccountManager from '../../managers/Account';

class ConfirmTransfer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      transferDetails: null,
      assetUrl: null,
      isEdrop: false,
      isError: false,
    };
  }

  componentDidMount = async () => {
    const { nftToken, isFree, onError } = this.props;
    let transfer;
    if (isFree) {
      this.setState({
        isLoading: false,
        transferDetails: {},
      });
    } else {
      transfer = await AccountManager.getTransferDetails(AccountManager.getToken(), nftToken);
    }
    if (transfer) {
      this.setState({
        transferDetails: {
          senderFirstName: transfer.senderFirstName,
          senderLastName: transfer.senderLastName,
          message: transfer.message,
          transferType: transfer.tokenType,
          creatorName: transfer.creatorName,
        },
        assetUrl: transfer.assetUrl,
        isLoading: false,
        isEdrop: transfer.tokenType,
      }, () => {
        console.log(transfer.transferDetails);
      });
    } else {
      this.setState({
        isLoading: false,
        isError: true,
      });
      // onError(nftToken, transfer && transfer.message ? transfer.message : 'Oops. Something went wrong. Please try again later.');
    }
  }

  confirmTransfer = () => {
    const { isFree, nftToken, onConfirm } = this.props;
    const { isEdrop } = this.state;
    this.setState({
      isLoading: true,
    });
    
    onConfirm(nftToken, isFree, isEdrop);
  }

  render() {
    const {
      isFree, nftToken, onConfirm, onCancel,
    } = this.props;
    const {
      isError, isEdrop, isLoading, transferDetails, assetUrl,
    } = this.state;
    return (
      <div className="min-height">
        {isLoading
          && <Throbber throbberText="Fetching transfer information. One second..." />}
        {transferDetails
        && (
        <div>
          {isFree
            ? (
              <div>
                {process.env.SITE_NAME}
                {' '}
                has sent you a free NFT.
              </div>
            )
            : (
              <div>
                {transferDetails.transferType === 'eDrop'
                  ? (
                    <span>
                      {transferDetails.creatorName}
                      {' '}
                    </span>
                  )
                  : (
                    <span>
                      {transferDetails.senderFirstName}
                      {' '}
                      {transferDetails.senderLastName}
                      {' '}
                    </span>
                  )}
                has sent you a free NFT.
              </div>
            )}
          <div className="form-blockquote-image">
            {assetUrl
          && (
          <div className="transfer-thumbnail">
            <img src={assetUrl} alt="" />
          </div>
          )}
            {transferDetails.message
          && (
          <blockquote className="form-blockquote">
            {'"'}
            {transferDetails.message}
            {'"'}
          </blockquote>
          )}
          </div>
          <div>Would you like to claim this now and add it to your collection?</div>
          <div className="form-cta">
            <button
                  className="button button-confirm"
                  type="button"
                  onClick={() => this.confirmTransfer()}>
              {isFree
                ? <span>Accept NFT</span>
                : <span>Accept transfer</span>}
            </button>
            &nbsp;
            <button
                  className="button"
                  type="button"
                  onClick={() => onCancel(nftToken)}>
              No thanks
            </button>
          </div>
        </div>
        )}
        {isError
          && (
          <div>
            This NFT transfer is no longer available
            <div className="form-cta">
              <button
                      className="button"
                      type="submit"
                      onClick={() => onCancel(nftToken)}>
                Close
              </button>
            </div>
          </div>
          )}
      </div>
    );
  }
}

ConfirmTransfer.propTypes = {
  nftToken: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  onError: PropTypes.func,
  isFree: PropTypes.bool,
};

export default ConfirmTransfer;
