import React, { Component } from 'react';
import { ToastContainer } from 'react-toastify';
import CookieConsent from 'react-cookie-consent';
import Countdown from 'react-countdown';
import moment from 'moment-mini';
import CreatorManager from '../managers/Creator';
import FullPageLoader from '../components/throbber/loader';
import Register from '../components/register';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropId: process.env.DROP_ID,
      dropData: null,
      isLoading: true,
      countdownData: null,
      friendlyDate: null,
    };
  }

  parseCountdown = (date) => {
    this.setState({
      countdownData: moment(date).unix() * 1000,
      friendlyDate: moment(date).format('ha  on dddd, MMMM D, YYYY'),
    });
  }

  getDropData = async () => {
    const { dropId } = this.state;
    const dropData = await CreatorManager.getDrop(dropId);
    this.parseCountdown(dropData.drop.countDown.countDownDate);

    if (dropData) {
      this.setState({
        isLoading: false,
        dropData,
      });
    }
  }

  setHeroHeight = () => {
    const hero = document.getElementById('countdownContainer');
    const bodyHeight = document.body.scrollHeight;
    const windowHeight = window.innerHeight;
    if (hero && (bodyHeight < windowHeight)) {
      hero.style.height = window.innerHeight + 'px';
    }
  }

  async componentDidMount() {
    const that = this;
    await this.getDropData();
    setTimeout(() => {
      that.setHeroHeight();
    }, 200);
    window.addEventListener('resize', that.setHeroHeight);
  }

  render() {
    const { countdownData, dropData, isLoading } = this.state;
    const renderer = ({
      days, hours, minutes, seconds, completed,
    }) => {
      if (completed) {
        window.location.href = '/';
      }
      // Render a countdown
      return (
        <div className="countdown-timer">
          <div className="countdown-timer__item">
            {days}
            {' '}
            <span>days</span>
          </div>
          <div className="countdown-timer__item">
            {hours}
            {' '}
            <span>hours</span>
          </div>
          <div className="countdown-timer__item">
            {minutes}
            {' '}
            <span>mins</span>
          </div>
          <div className="countdown-timer__item">
            {seconds}
            {' '}
            <span>secs</span>
          </div>
        </div>
      );
    };
    return (
      <div>
        {isLoading
          ? (
            <div>
              <FullPageLoader />
            </div>
          )
          : (
            <div>
              {dropData
                && (
                <div>
                  <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    pauseOnHover />

                  <div
                    id="countdownContainer"
                    className="countdown-full-container"
                    style={{
                      backgroundImage: 'url(' + process.env.COUNTDOWN_BACKGROUND + ')',
                    }}>
                    <div className="countdown-counter-container">
                      <div className="countdown-counter-content">
                        <h1>NFTs launch in:</h1>
                        
                        {countdownData
                        && (
                          <Countdown
                            date={countdownData}
                            renderer={renderer} />
                        )}
                      </div>
                    </div>
                    <div
                        className="countdown-form-container"
                        style={{
                          backgroundImage: 'url(' + process.env.FORM_BACKGROUND + ')',
                        }}>
                      <div id="countdownForm" className="countdown-form-container__inner">
                        <img className="countdown-form__logo" src={process.env.LARGE_LOGO} width={process.env.LARGE_LOGO_WIDTH} alt="" />
                        <div className="countdown-form__box">
                          <Register
                            buttonType="block"
                            onRegisterSuccess={() => this.setHeroHeight()} />
                        </div>
                        <div className="countdown-form__footer">
                          &copy; 2022 ethos multiverse inc.
                        </div>
                      </div>
                    </div>
                  </div>

                  <CookieConsent
                    cookieName={process.env.CREATOR_SUBDOMAIN + 'CookieConsent'}>
                    <a href="https://www.ethosnft.com">ethos</a>
                    {' '}
                    uses cookies to provide necessary website functionality,
                    improve your experience and analyze our traffic. By using our website, you agree to&nbsp;
                    <a target="_blank" href="https://www.ethosnft.com/privacy" rel="noreferrer">our privacy policy</a>
                    {' '}
                    and our cookies usage.
                    
                  </CookieConsent>
                </div>
                )}
            </div>
          )}
      </div>
    );
  }
}

export default Home;
