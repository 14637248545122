import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { isIOS } from '../../managers/Helpers';

const ElipsDropdown = ({
  nft, chainLink, onTransferButton, onGenerateApplePass,
}) => {
  const [hasApplePass, setHasApplePass] = useState(false);
  const [showNav, setShowNav] = useState(false);

  const allowApplePass = () => {
    // if (nft.drop.name === 'Future Rewards Program' || nft.drop.name === 'Tokens By Future') {
    //   setHasApplePass(true);
    // }
    if (nft.passTemplate && isIOS()) {
      setHasApplePass(true);
    }
    return false;
  };

  const generateApplePass = () => {
    setShowNav(false);
    onGenerateApplePass();
  };

  const transferNft = () => {
    setShowNav(false);
    onTransferButton();
  };

  const toggleNav = () => {
    if (showNav) {
      setShowNav(false);
    } else {
      setShowNav(true);
    }
  };

  useEffect(() => {
    allowApplePass();
  }, []);

  return (
    <div className="dropdown dropdown-end">
      <button
        className={showNav ? 'dropdown-btn open' : 'dropdown-btn'}
        type="button"
        label="button"
        onClick={() => toggleNav()}>
        <FontAwesomeIcon icon={faEllipsisVertical} />
      </button>
      <div className={showNav ? 'bg-base-200 text-base-content rounded-t-box rounded-b-box top-px overflow-y-auto shadow-2xl w-100 dropdown-content open' : 'bg-base-200 text-base-content rounded-t-box rounded-b-box top-px overflow-y-auto shadow-2xl w-100 dropdown-content'}>
        <ul className="menu menu-compact">
          {/* {hasApplePass && isIOS() */}
          {hasApplePass
          && (
          <li>
            <button
              tabIndex="0"
              className="button-nav -block"
              type="button"
              onClick={() => generateApplePass()}>
              {/* <img loading="lazy" width="20" height="20" alt="English" src="https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.0/svg/1f4f2.svg" /> */}
              Add to Apple Wallet
            </button>
          </li>
          )}
          {nft.withdrawAddress
            ? (
              <li>
                <a tabIndex="0" onClick={() => toggleNav()} href={chainLink} target="_blank" className="button-nav -centered -block" rel="noreferrer">
                  {/* <img loading="lazy" width="20" height="20" alt="" src="https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.0/svg/1f50e.svg" /> */}
                  View on blockchain
                </a>
              </li>
            )
            : (
              <li>
                <button
                tabIndex="0"
                className="button-nav -block"
                type="button"
                onClick={() => transferNft()}>
                  {/* <img loading="lazy" width="20" height="20" alt="" src="https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.0/svg/1f50e.svg" /> */}
                  Transfer NFT
                </button>
              </li>
            )}
        </ul>
      </div>
    </div>
  );
};
export default ElipsDropdown;
