import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import SimpleModal from '../modal/modal';

const TransferChecker = ({ isAuthed, transferToken }) => {
  const [showModal, setShowModal] = useState(true);
  const optOut = () => {
    sessionStorage.removeItem(transferToken);
    window.location.href = '/';
  };
  return (
    <div>
      <SimpleModal isOpen={showModal} onBeforeClose={() => setShowModal(false)}>
        <div className="account-heading">
          Claim your digital asset
        </div>
        {isAuthed
          ? (
            <div>
              Go to your collections page to claim this free digital asset.
            </div>
          )
          : (
            <div>
              Create an account or log in to claim this free digital asset.
            </div>
          )}
        <div className="form-cta">
          {isAuthed
            ? (
              <a href="/account/collection" className="button">
                Go to collections
              </a>
            )
            : (
              <Link
                to="/login"
                className="button">
                Log in
              </Link>
            )}
          &nbsp;
          <button onClick={() => optOut()} type="button" className="button button-cancel">No thanks!</button>
        </div>
        {!isAuthed
        && (
        <div className="account-register__cta">
          <div className="account-subheading -slim-padding">
            {'Don\'t have an ethos account?'}
          </div>

          <div className="form-cta">
            <Link
                to="/register"
                className="button button-register">
              Register Now
            </Link>
          </div>
        </div>
        )}
      </SimpleModal>
    </div>
  );
};
export default TransferChecker;
